//
// Text color
//

// Gray text

@each $color, $value in $grays {
  .text-gray-#{$color} {
    color: $value !important;
  }
}

// Text opacity
.text-white-70 {
  color: rgba($white, .7) !important;
}

.text-white-75 {
  color: rgba($white, .75) !important;
}

.text-white-80 {
  color: rgba($white, .8) !important;
}

.text-muted-80 {
  color: rgba($text-muted, .8) !important;
}
