@media (prefers-color-scheme: dark) {
  body {
    background-color: $black;
    color: $gray-600;
  }
  .table {
    th, td {
      border-color: $gray-800;
      color: $gray-500;
    }
    thead {
      th, td {
        border-color: $gray-800;
        color: $gray-500;
      }
    }
  }
  hr {
    border-top: 1px solid $gray-800;
  }
  .subsection {
    border-bottom: 1px solid $gray-900 !important;
  }
  .breadcrumb {
    background-color: darken($black, 5%);
    color: $gray-600;
  }
  h1, h2, h3, h4, h5 {
    color: $gray-500;
  }
  .form-control {
    background: $gray-900;
    color: $gray-500;
    &:focus {
      background: $gray-800;
      color: $gray-500;
    }
  }
  input {
    background: $gray-900;
    color: $gray-500;
  } // used in spam catch on contact form
  .bg-light {
    background-color: rgba(255,255,255,.1) !important;
    color: $gray-500;
  }

}
