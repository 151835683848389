footer {
  padding: $padded-most * 2 0;
  .social {
    padding: $padded-more 0;
    a {
      display: block;
      text-align: center;
    }
  }
}
