section.naics-group {
  // sub-section headers are h2s
  h2 {
    font-size: 1.8em;
  }
  // sidebar table of contents for intra-page nav
  .toc {

    h1 {
      font-size: 1.65rem;
      font-weight: 400;
    }
    h2 {
      padding: .5rem 0;
      font-size: 1.2em;
      font-weight: 800;
    }

    .toc-entry {
      display: block;

      a {
        display: block;
        padding: .125rem 1.5rem;
        color: #77757a;

        &:hover {
          color: $blue;
          text-decoration: none;
        }
      }
    }


    nav {
      .nav-link {
        padding-left: 0;
      }
    }
  }
  .subsection {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $gray-200;
    &:last-of-type {
      border-bottom: 0;
    }
  }
  .anchor {
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
    &:last-of-type {
      top: 0;
    }
  }

}
