@import "variables";

/* 3rd Party SCSS -----------------------------*/
@import "bootstrap"; // core front-end framework used by AskKodiak

// Font awesome.
@import "fontawesome"; // scalable vector icons for AskKodiak
@import "regular";// 400 weight font-awesome icons.
@import "solid";// 900 weight font-awesome icons.
@import "light";// 300 weight font-awesome icons.
@import "duotone";// duotone font-awesome icons.
@import "brands";// fontawesome brand icons

/* Ask Kodiak SCSS -----------------------------*/
@import "fonts";
@import "helpers";
@import "jumbotron";
@import "navbar";
@import "footer";
@import "type";
@import "legal";

@import "index";
@import "naics/group";
@import "404";
@import "methodology";

@import "dark-mode";
