// Colors
$white: #FFFFFF;
$gray-100: #F9FBFD;
$gray-200: #F1F4F8;
$gray-300: #D9E2EF;
$gray-400: #C6D3E6;
$gray-500: #cccccb;
$gray-600: #869AB8;
$gray-700: #506690;
$gray-800: #384C74;
$gray-900: #1B2A4E;
$black: #161C2D;

$blue:    #21b2fe;
$orange:  #ffbd54;
$green:   rgb(137, 218, 87);

$primary: $blue;
$secondary: $gray-700;
$success: $green;

$warning: $orange;
$light: $gray-100;
$dark: $gray-900;

// Misc

$padded: 25px;
$padded-less: 15px;
$padded-more: 50px;
$padded-most: 50px;

// Typography
/*

Typekit Fonts:

font-family: neue-haas-unica,sans-serif;
font-weight: 400;
font-style: normal;
*/
$font-family-sans-serif: "neue-haas-unica", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$headings-font-family: "neue-haas-unica";
$headings-font-weight: 700;
$display1-weight: 600;
$display2-weight: 600;
$display3-weight: 600;
$display4-weight: 600;

$font-path: '/assets/fonts';
$fa-font-path: '/assets/fonts';

$font-weight-normal: 300;
$font-weight-bold: 500;
