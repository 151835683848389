body {
  padding-top: 3.5rem;
}
code.debug {
  display: block;
  white-space: pre;
  color: $gray-400;
  background: $black;
}
.mono {
  font-family: $font-family-monospace;
}
.tiny-80 {
  font-size: .8em;
}
.tiny-70 {
  font-size: .7em;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.center {
  text-align: center;
}
.pre {
  white-space: pre-wrap;
}
.title-case {
  text-transform: capitalize;
}
